
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import MediaImageStrip from "@/components/media/MediaImageStrip.vue"
import HealthStatusChip from "./HealthStatusChip.vue"
import { BaseHealthReportComponent, RequiredProjections } from "../base/BaseMixins";
import { SystemMediaResource } from "@/resources";

@RequiredProjections("healthReportDetail")
@Component({components:{HealthStatusChip, MediaImageStrip}})
export default class HealthReportNavigatorPanel extends Mixins(BaseHealthReportComponent) {

    @Prop({default: "auto"}) height !: string
    @Prop() descriptionHeight !: string
    @Prop({default: true}) constrained !: boolean

    @Watch("isDirty")
    reportChanged(isDirty : any) {
      if (isDirty && this.$refs.container) {
        let cObj : any = this.$refs.container
        cObj.scrollTop = 0
      }
    }

    get hasMedia() {
      return this.rdata.media && this.rdata.media.length > 0
    }

    get mediaUploadUrl() {
      return SystemMediaResource.mediaUploadUrl
    }
    
}
