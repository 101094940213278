
import {Component, Prop} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import BaseResourceAssociationDataTable from "@/components/base/BaseResourceAssociationDataTable.vue"
import { ClientResource, MaintenanceJobResource, PropertyResource} from '@/resources'
import JobStatusChip from './JobStatusChip.vue'
import { MODE_ADD_EDIT } from './JobFormDialog.vue'
import JobFormDialog from './JobFormDialog.vue'
import { BaseMaintenanceJobComponent, RequiredProjections } from '../base/BaseMixins'
const { DateTime } = require("luxon")

export const DEFAULT_OPEN_HEADERS = [
      { text: 'Status', value: 'status', sortable: false, cellClass:'status'},
      { text: 'Details', value: 'details', width: "100%", sortable: false, cellClass:'details'},
      { text: 'Start date', value: 'startDate', sortable: false, cellClass:'startDate'},
      { value: 'actions', sortable: false, cellClass:'actions'}
]
export const DEFAULT_CLOSED_HEADERS = [
      { text: 'Status', value: 'status', sortable : false, cellClass:'status'},
      { text: 'Details', value: 'details', width: "100%", sortable: false, cellClass:'details'},
      { text: 'Closed date', value: 'endDate', sortable: false, cellClass:'endDate'}
]


@RequiredProjections("maintenanceJobSummary")
@Component({components: {JobStatusChip, BasePanel, BaseOptionsMenu, JobFormDialog, BaseResourceAssociationDataTable}})
export default class JobsTable extends BaseMaintenanceJobComponent {

  @Prop({default: () => DEFAULT_OPEN_HEADERS}) headers !: any
  @Prop({default: false}) hidePropertyAndClient !: boolean

  jobDialog : boolean = false
  jobFormMode = MODE_ADD_EDIT
  jobToEdit : MaintenanceJobResource | null = null
  jobToEditProperty : PropertyResource | null = null

  async editJob(job : any) {
    this.jobFormMode = MODE_ADD_EDIT
    this.jobToEdit = new MaintenanceJobResource(job.id)
    this.jobToEditProperty = new PropertyResource(job.property.id)
    await this.jobToEdit.get()
    await this.jobToEditProperty.get()
    
    this.jobDialog = true
  }

  get dateMed() {
    return DateTime.DATE_MED
  }

  get items() {
    return this.ready ? this.rdata : []
  }

  clientName(client : any) {
    return ClientResource.getClientName(client)
  }
  propertyName(property : any) {
    return PropertyResource.getPropertyAddress(property)
  }

  gotoJob(job : any) {
    this.$router.push({ name: 'job', params: {jid:job.id, pid : job.property.id, pmsId: job.propertyMaintenanceSystem.id}})
  }

  get jobs() {
    return this.rdata
  }

}
