
import {Component, InjectReactive, Prop} from 'vue-property-decorator'
import {AssociationResource, EntityResource, HealthReportResource, MaintenanceJobResource, PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseHealthReportComponent } from '../base/BaseMixins'
import HealthReportListItem from "./HealthReportListItem.vue"
import HealthReportNavigatorDialog from './HealthReportNavigatorDialog.vue'
import HealthReportFormDialog from './HealthReportFormDialog.vue'
import VirtualScroll from 'vue-virtual-scroll-list'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import HealthReportNavigatorPanel from './HealthReportNavigatorPanel.vue'

@Component({components: {BaseDeleteAssociationResourceItemDialog, BaseOptionsMenu, HealthReportNavigatorPanel, VirtualScroll, HealthReportFormDialog, BasePanel, HealthReportListItem, HealthReportNavigatorDialog}})
export default class HealthReportsList extends BaseHealthReportComponent {

  @InjectReactive() propertyResource !: PropertyResource
  @InjectReactive() propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource
  @Prop({default: null}) readonly maintenanceJobResource !: MaintenanceJobResource

  navigatorDialog : boolean = false
  editDialog : boolean = false
  selectedResource : EntityResource | null = null
  customSortField = (er : HealthReportResource) => er.data().reportDate
  deleteDialog : boolean = false
  deletionAssociationResource : AssociationResource<HealthReportResource | HealthReportResource[]> | null = null
  
  // TODO duplicate in HealthReportNavigatorDialog
  showDeleteDialog(eventResource : HealthReportResource) {
    // health reports are a bit odd, in that they can belong to the PMS, or to a specific job within a PMS.   
    // PMS health summaries show all the reports (pms or job specific)
    // if deleting an HR, and the HR has a job, we are detaching from the job and not the PMS, so we need
    // to select the right association resource.
    this.deletionAssociationResource = null
    eventResource.job.getAssociation().then((jr) => {

      // relationship is job HR
      this.deletionAssociationResource = jr.healthReport
    })
    // no job ? relationship is list of HR for PMS
    .catch((e) => {
      if (e?.status === 404) {
        this.deletionAssociationResource = this.associationResource
      }
    })
    .finally (() => this.deleteDialog = true)
  }

  get resourceToEdit() {
    if (this.isSingle && this.size) {
      return new HealthReportResource(this.resources[0].data().id)
    }
    return undefined
  }

  jobHealthResource(resource : HealthReportResource) {
    return new HealthReportResource(resource.data().id)
  }

  get title() {
    return "Health report" + (this.isSingle ? "" : "s " + (this.size ? " (" + this.size + ")" : ""))
  }

  get height() {
    return this.isSingle ? undefined : 300
  }

  get isSingle() {
    return !this.associationResource.isArray
  }

  select(hr : any) {
    this.selectedResource = hr
    this.navigatorDialog = true
  }

}
