
import { Component, InjectReactive, Prop } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import RecurrenceControl from "@/components/serviceLoops/RecurrenceControl.vue"
import BaseResourceSelector from "@/components/base/BaseResourceSelector.vue"
import { AssociationResource, JobTemplateResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources"
import AsyncComputed from 'vue-async-computed-decorator'
import { cloneDeep } from "lodash-es"
import { LinkVariables } from "ketting/dist/link"
import PropertyMaintenanceSystemInputField from "../properties/PropertyMaintenanceSystemInputField.vue"


@Component({components : {PropertyMaintenanceSystemInputField, RecurrenceControl, BaseResourceSelector}})
export default class ServiceLoopForm extends ValueBasedFormsMixin {
  @Prop({required : true}) readonly jobTemplatesAssociationResource !: AssociationResource<JobTemplateResource[]>
  @InjectReactive() propertyResource!: PropertyResource

  getHalFormName() : string { return "serviceloop" }

  // TODO same as job form
  isSimpleJob : boolean = false
  hasMultiplePMS : boolean = false
  multiplePMSSearchParams : LinkVariables | null = null
  multiplePropertyMaintenanceSystems : AssociationResource<PropertyMaintenanceSystemsResource[]> = new PropertyMaintenanceSystemsResource().sameMaintenanceSystem


  @AsyncComputed()
  async jobTemplateResources() {
    var jobs = await this.jobTemplatesAssociationResource.getAssociation()
    return jobs
  }

  jobTemplateNameProvider(jt : any) {
    return jt.data().maintenanceSystemName + " : " + jt.data().name
  }

  get jobProps() {
    return {
      label: "Maintenance job type",
      placeholder: "Select a job type from the list",
      'validate-on-blur' : true,
      type: "select"
    }
  }

  /**
   * Extract assign job template to service loop.
   * TODO similar to Job Form
   * @param uri 
   */
  async updateJobDetails(uri : any) {

    this.hasMultiplePMS = false
    this.isSimpleJob = false

    // can't use doUpdate() as we need to set multiple props, then issue the input event
    let clonedValue = cloneDeep(this.getValue())
    clonedValue.jobTemplate = uri

    if (uri == -1) {
      this.isSimpleJob = true
      clonedValue.jobTemplate = null
    }
    // else not simple job, need to test for multiple PMS for the given JT
    else {
      const job = (await new JobTemplateResource(uri + "?projection=jobTemplateDetail").get()).data

      // get all PMS with same MS for this property, if none, create on fly, if one use it, else we need user to determine
      // TODO without cache as new PMS won't show up... SSE should be tied to resource
      this.multiplePMSSearchParams = {pid: this.propertyResource.data().id, msid: job.systemId, projection : "propertyMaintenanceSystemSummary"};
      let search = (await this.multiplePropertyMaintenanceSystems.getAssociation(this.multiplePMSSearchParams, false))

      // if none, we need to create on fly, store MSID
      if (search.length == 0) {
        clonedValue.newSystemMid = job.systemId
        clonedValue.propertyMaintenanceSystem = null
      }
      // if one use it
      else if (search.length == 1) {
        clonedValue.newSystemMid = null
        clonedValue.propertyMaintenanceSystem = search[0].uriFull
      }
      // else user needs to choose (leave unset so user has to choose)
      else {
        clonedValue.propertyMaintenanceSystem = null
        clonedValue.newSystemMid = null
        this.hasMultiplePMS = true
      }
    }

    // update
    this.$emit("input", clonedValue)
  }

}
