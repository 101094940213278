
import {Vue, Component, InjectReactive, Watch} from 'vue-property-decorator'
import {PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import HealthReportsList from "@/components/healthreports/HealthReportsList.vue"
import MediaListComponent from "@/components/media/MediaListComponent.vue"
import AsyncComputed from 'vue-async-computed-decorator'
import JobLogsPanel from '@/components/jobs/JobLogsPanel.vue'
import PropertyMaintenanceSystemInfo from "@/components/properties/PropertyMaintenanceSystemInfo.vue"
import JobFormDialog from "@/components/jobs/JobFormDialog.vue"
import ServiceLoopsList from '../serviceLoops/ServiceLoopsList.vue'
import PropertyMaintenanceItemsPanel from "@/components/properties/PropertyMaintenanceItemsPanel.vue"
import PropertyMaintenanceSystemName from './PropertyMaintenanceSystemName.vue'
import HealthSummaryChips from '../healthreports/HealthSummaryChips.vue'
import JobsTable from "@/components/jobs/JobsTable.vue"
import BasePanel from '../base/BasePanel.vue'
@Component({metaInfo: {title:process.env.VUE_APP_NAME + " - Maintenance System View"}, components: {JobLogsPanel, BasePanel, JobsTable, HealthSummaryChips, PropertyMaintenanceSystemName, PropertyMaintenanceItemsPanel, ServiceLoopsList, JobFormDialog, PropertyMaintenanceSystemInfo, MediaListComponent, HealthReportsList}})
export default class PropertyMaintenanceSystemDetail extends Vue {
  
  @InjectReactive() propertyResource !: PropertyResource
  @InjectReactive() propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource

  addJobDialog : boolean = false

  allMaintenanceSystemUrls : string[] = []
  maintenanceItemsRollupWatchUrls : string[] = []

  // NOTE: https://jareklipski.medium.com/linking-to-a-specific-tab-in-vuetify-js-d978525f2e1a
  set tab(tab) {
    this.$router.replace({ query: { ...this.$route.query, tab } })
  }
  get tab() {
    return this.$route.query.tab
  }

  @Watch("propertyResource", {immediate: true})
  propertyChanged() {
    this.propertyResource.maintenanceSystems.getAssociation({}).then(r => {
      this.allMaintenanceSystemUrls = r.map(pms => new URL(pms.uriFull).pathname)
    })
  }

  systemWatchUrls(suffix : string) {
    return this.allMaintenanceSystemUrls.map(s => s + suffix)
  }

  get itemWatchUrls() {
    // e.g. when adding something to a PMS, we will get a REL ADD
    // REL-ADD /api/propertyMaintenanceSystems/2990/maintenanceItems
    // TODO true or false here ?   We don't update an underlying resource do we when a
    // BaseResourceComponent using an assocaition is updated ?
    return [...this.systemWatchUrls("/maintenanceItems"), "/api/propertyMaintenanceSystems/.+/maintenanceItems"]
  }

  get serviceLoopWatchUrls() {
    // service loops of existing pms, and additions to existing systems
    return [...this.systemWatchUrls("/serviceLoops"), ...this.systemWatchUrls("/subsystems")]
  }
  get jobWatchUrls() {
    return [...this.systemWatchUrls("/jobs"), "/api/propertyMaintenanceSystems/.+/jobs"]
  }
  get mediaWatchUrls() {
    return this.systemWatchUrls("/media")
  }
  get reportWatchUrls() {
    return this.systemWatchUrls("/healthReports")
  }

  get uploadUrl() {
    return process.env.VUE_APP_API_BASE_URL + '/systemMedias/upload'
  }

  get propertyMaintenanceSystemSummary() {
    return new PropertyMaintenanceSystemsResource(this.propertyMaintenanceSystem.data().id + "?projection=propertyMaintenanceSystemSummary")
  }

  get propertyMaintenanceSystemsAssociation() {
    return this.propertyResource.maintenanceSystems
  }

  @AsyncComputed()
  async maintenanceSystem() {
   return this.propertyMaintenanceSystem.maintenanceSystem.getAssociation()
  }


}
