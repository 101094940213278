
import {Component, InjectReactive, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, AssociationResource, JobTemplateResource, PropertyMaintenanceSystemsResource, PropertyResource, MaintenanceSystemsResource} from "@/resources"
import { BaseServiceLoopComponent, RequiredProjections } from '../base/BaseMixins'
import ServiceLoopForm from './ServiceLoopForm.vue'

@RequiredProjections("serviceLoopDetail")
@Component({components: {BaseResourceFormDialog, ServiceLoopForm}})
export default class ServiceLoopFormDialog extends Mixins(ResourceFormDialogMixin, BaseServiceLoopComponent) { 

  @Prop({required : true}) readonly jobTemplatesAssociationResource !: AssociationResource<JobTemplateResource[]>
  @InjectReactive() propertyResource!: PropertyResource

  newModelFunction() : any {

    // always new for custom loops, we don't allow editing existing loops, user can simply
    // delete and re-add, doesn't affect any existing jobs, just a tool to spawn jobs and
    // provide instruction/
    return {...SettingsResource.defaultObject("serviceLoop"), newSystemMid : null}
  }

  async preSaveCallback(formModel : any) {
    if (!formModel.propertyMaintenanceSystem && formModel.newSystemMid) {       
      // create new PMS and set in form model
      try {
        let pmsUri = (await new PropertyMaintenanceSystemsResource().post({
          property : this.propertyResource.uriFull,
          maintenanceSystem : new MaintenanceSystemsResource(formModel.newSystemMid).uriFull
        })).uri

        formModel.propertyMaintenanceSystem = pmsUri
      }
      catch (e: any) {
          console.error("Could not create new maintenannce system (pid: " + this.propertyResource.data().id + ", mid: " + formModel.newSystemMid + "): " + e);
          throw e
      }
    }
    return Promise.resolve()
  }

}
