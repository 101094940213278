
import {Component, Prop} from 'vue-property-decorator'
import {EntityResource} from "@/resources"
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseMaintenanceJobLogComponent } from '../base/BaseMixins'
import JobLogFormDialog from './JobLogFormDialog.vue'
import JobLogNavigatorDialog from './JobLogNavigatorDialog.vue'
import JobLogsList from './JobLogsList.vue'
const { DateTime } = require("luxon")

@Component({components: {JobLogNavigatorDialog, BasePanel, JobLogFormDialog, JobLogsList}})
export default class JobLogsPanel extends BaseMaintenanceJobLogComponent {

  @Prop({default: false}) hideAdd !: boolean

  navigatorDialog : boolean = false
  editDialog : boolean = false
  selectedResource : EntityResource | null = null
  
  // TODO filter and all other occurences
  formatDate(d : any) {
    return DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_MED)
  }
  // TODO filter
  dateRelative(theDate : string) {
    return DateTime.fromISO(theDate).toRelative()
  }

  get title() {
    return "Maintenance logs" + (this.size ? " (" + this.size + ")" : "")
  }


  select(hr : any) {
    this.selectedResource = hr
    this.navigatorDialog = true
  }

}
