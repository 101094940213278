
import { AssociationResource, EntityResource, HealthReportResource, PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources"
import { Component, InjectReactive, Prop } from "vue-property-decorator"
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import BaseEventTimelineNavigatorDialog from "@/components/base/BaseEventTimelineNavigatorDialog.vue"
import { BaseHealthReportComponent } from "../base/BaseMixins"
import InstantUpdateBooleanIconButton from '@/components/forms/InstantUpdateBooleanIconButton.vue'
import HealthReportNavigatorPanel from "./HealthReportNavigatorPanel.vue"
import HealthReportFormDialog from "./HealthReportFormDialog.vue"
import HealthReportIcon from "./HealthReportIcon.vue"

@Component({components:{InstantUpdateBooleanIconButton, HealthReportNavigatorPanel, HealthReportIcon, HealthReportFormDialog, BaseEventTimelineNavigatorDialog, BaseDeleteAssociationResourceItemDialog}})
export default class HealthReportNavigatorDialog extends BaseHealthReportComponent {
    
    @InjectReactive() propertyResource !: PropertyResource
    @InjectReactive() propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource

    @Prop({ required : true, default: false}) readonly value!: boolean
    @Prop() readonly startingResource!: EntityResource

    editDialog : boolean = false
    deleteDialog : boolean = false
    deletionAssociationResource : AssociationResource<HealthReportResource | HealthReportResource[]> | null = null
    
    showDeleteDialog(eventResource : HealthReportResource) {
      // health reports are a bit odd, in that they can belong to the PMS, or to a specific job within a PMS.   
      // PMS health summaries show all the reports (pms or job specific)
      // if deleting an HR, and the HR has a job, we are detaching from the job and not the PMS, so we need
      // to select the right association resource.
      this.deletionAssociationResource = null
      eventResource.job.getAssociation().then((jr) => {

        // relationship is job HR
        this.deletionAssociationResource = jr.healthReport
      })
      // no job ? relationship is list of HR for PMS
      .catch((e) => {
        if (e?.status === 404) {
          this.deletionAssociationResource = this.associationResource
        }
      })
      .finally (() => this.deleteDialog = true)
    }
}
