
import {Component, InjectReactive, Watch} from 'vue-property-decorator'
import {EntityResource, FormsMetadataResource, JobTemplateResource, MaintenanceSystemsResource, PropertyMaintenanceSystemsResource, PropertyResource, ServiceLoopResource, ServiceLoopTemplateResource} from "@/resources"
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseServiceLoopComponent } from '../base/BaseMixins'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import AsyncComputed from 'vue-async-computed-decorator'
import InstantUpdateBooleanIconButton from '../forms/InstantUpdateBooleanIconButton.vue'
import ServiceLoopFormDialog from './ServiceLoopFormDialog.vue'
import JobFormDialog from '../jobs/JobFormDialog.vue'

@Component({components: {BasePanel, JobFormDialog, ServiceLoopFormDialog, InstantUpdateBooleanIconButton, BaseOptionsMenu}})
export default class ServiceLoopsList extends BaseServiceLoopComponent {

  @InjectReactive() propertyResource!: PropertyResource
  @InjectReactive() propertyMaintenanceSystem!: PropertyMaintenanceSystemsResource

  customDialog : boolean = false
  deleting : any={}
  addingFromTemplate : any = {}
  addJobDialog : boolean = false
  addJobServiceLoop : ServiceLoopResource | null = null
  selectedResource : EntityResource | null = null

  maintenanceSystemResource : MaintenanceSystemsResource | null = null
  groupedServiceLoopTemplates : any = {}

  scheduleSearch : string  = ""
  showScheduleSearch : boolean = false
  loadingSchedules : boolean = false

  headers: any = [
        { text: 'Name', value: 'jobName', cellClass: "text-no-wrap", sortable: true },
        //{ text: 'Description', value: 'jobDescription' },
        // { text: 'Job type', align: 'start', sortable: true, value: 'jobWorkflowKey'},
        { text: '', value: 'actions', sortable: false, align: "end" },
      ]

  get activeLoops() {
    let loops = this.activeLoopResources ? this.activeLoopResources.map((l : any) => l.data()) : []
    return loops
  }
  get activeLoopResources() {
    let activeLoops = this.resources ? this.resources.filter((l : any) => !l.data().isArchived) : []
    let sortedLoops = activeLoops.sort((aR : any, bR : any) => {
      let a = aR.data()
      let b = bR.data()
      let aName = a.jobTemplate ? a.jobTemplate.name : (a.simpleJobName ? a.simpleJobName : "Simple job")
      let bName = b.jobTemplate ? b.jobTemplate.name : (b.simpleJobName ? b.simpleJobName : "Simple job")
      return aName.localeCompare(bName)
    })
    return sortedLoops
  }

  get workflowEnums() {
    return FormsMetadataResource.Instance.getHalOptions("jobtemplate", "workflowKey")
  }

  @Watch("addJobDialog")
  addJobDialogChanged(newVal : any) {
    if (!newVal) {
      this.addJobServiceLoop = null
    }
  }

  addJobFromLoop(loop : any) {
    this.addJobServiceLoop = loop
    this.addJobDialog = true
  }

  deleteLoop(loop : any) {
    // TODO errors
    
    // TODO we do this in other places... make this easier, delete resource button ?
    this.$set(this.deleting, loop.uriFull, true)
    loop.delete().finally(() => this.$set(this.deleting, loop.uriFull, false))
  }

  async addFromTemplate(template : ServiceLoopTemplateResource) {

    // check if pms exists for the selected template, if not we need to create it
    // create new PMS and set in form model
    this.$set(this.addingFromTemplate, template.data().id, true)

    const pid = this.propertyResource.data().id
    const msid = template.data().maintenanceSystemId

    const search = await PropertyMaintenanceSystemsResource.searchForMultiple(pid, msid)
    let pmsUrl
    let pmsNew = false
    if (search.length == 0) {
      try {
        pmsUrl = (await new PropertyMaintenanceSystemsResource().post({
          property : this.propertyResource.uriFull,
          maintenanceSystem : new MaintenanceSystemsResource(msid).uriFull
        })).uri
        pmsNew = true
      }
      catch (e: any) {
          console.error("Could not create new property maintenance system (pid: " + pid + ", mid: " + msid + "): " + e);
          throw e
      }
      finally {
        this.$set(this.addingFromTemplate, template.data().id, false)
      }
    }
    // TODO assume first
    else {
      pmsUrl = search[0].uriFull
    }

    let slData = template.data()
    var model = {
      jobTemplate : new JobTemplateResource(slData.jobTemplate.id).resource.uri,
      loopRecurrence : slData.recurrence,
      propertyMaintenanceSystem : pmsUrl
    }
    new ServiceLoopResource().post(model)
      .then(() => {
        // TODO if a new pms, we have nothing to listen to... better event system
        if (pmsNew) this.update()
      })
      .catch((e) => console.error(e))
      .finally(() => this.$set(this.addingFromTemplate, template.data().id, false))
  }

  workflowName(key : any) {
    const enumValue = this.workflowEnums.find((wf:any) => wf.value === key)
    return enumValue ? enumValue.text : "UNKNOWN"
  }  

  get title() {
    return "Maintenance schedules " + (this.activeLoops.length ? " (" + this.activeLoops.length + ")" : "")
  }


  get filteredGroupedServiceLoopTemplatesCount() {
    return Object.keys(this.filteredGroupedServiceLoopTemplates).reduce((acc, key) => {return acc + this.filteredGroupedServiceLoopTemplates[key].length}, 0)
  }
  get filteredGroupedServiceLoopTemplates() {
    if (!this.scheduleSearch.trim() || !this.showScheduleSearch) {
      return this.groupedServiceLoopTemplates
    }
    let filtered : any = {}
    Object.keys(this.groupedServiceLoopTemplates).forEach(key => {
      let schedules = this.groupedServiceLoopTemplates[key].filter((sl : ServiceLoopTemplateResource) => sl.data().jobTemplate.name.toLowerCase().indexOf(this.scheduleSearch.toLowerCase()) > -1)
      if (schedules.length > 0) {
        filtered[key] = schedules
      }
    })
    return filtered
  }

  @AsyncComputed()
  async loadMaintenanceSystem() {
    this.loadingSchedules = true;
    
    this.maintenanceSystemResource = await this.propertyMaintenanceSystem.maintenanceSystem.getAssociation()
     
    // group by system
    try {
      const serviceLoopTemplates = await this.maintenanceSystemResource.serviceLoopTemplatesRolledUp.getAssociation()
      serviceLoopTemplates.forEach(sl => {
          const msName = sl.data().maintenanceSystemName
          if (!this.groupedServiceLoopTemplates[msName]) {
            this.$set(this.groupedServiceLoopTemplates, msName, [])
          }
          this.groupedServiceLoopTemplates[msName].push(sl)
      })
    }
    finally {
      this.loadingSchedules = false
    }

  }

  select(r : any) {
    this.selectedResource = r
  }

}
